import { IconButton } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import React from 'react';

const Export = ({ coordinator, dataset }) => {
  async function handleExportToExcel(e) {
    e.preventDefault();

    try {
      // Create a new workbook and worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Product');

      worksheet.properties.defaultColWidth = 20;
      worksheet.getColumn(3).width = 10;
      worksheet.getRow(1).height = 40;

      worksheet.mergeCells('A1:C1');
      const coordinatorCell = worksheet.getCell('A1');
      coordinatorCell.font = { bold: true, size: 12 };
      coordinatorCell.fill = {
        fgColor: { argb: '82CA40' },
        pattern: 'solid',
        type: 'pattern',
      };
      coordinatorCell.alignment = {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true,
      };
      coordinatorCell.value = `${coordinator[0]}, ${coordinator[1]},\n${coordinator[2]}`;

      const studentsHeaderRow = worksheet.addRow([
        'Meno',
        'Priezvisko',
        'Trieda',
      ]);
      studentsHeaderRow.getCell(1).font = { bold: true, size: 12 };
      studentsHeaderRow.getCell(2).font = { bold: true, size: 12 };
      studentsHeaderRow.getCell(3).font = { bold: true, size: 12 };
      studentsHeaderRow.getCell(1).fill = {
        fgColor: { argb: '82CA40' },
        pattern: 'solid',
        type: 'pattern',
      };
      studentsHeaderRow.getCell(2).fill = {
        fgColor: { argb: '82CA40' },
        pattern: 'solid',
        type: 'pattern',
      };
      studentsHeaderRow.getCell(3).fill = {
        fgColor: { argb: '82CA40' },
        pattern: 'solid',
        type: 'pattern',
      };

      let count = 0;
      let totalCount = 0;
      let iClass = 0;

      dataset.forEach((row) => {
        if (iClass === 0) {
          iClass = row[2];
          count = 1;
          totalCount = 1;
        } else if (iClass !== row[2]) {
          const countRow = worksheet.addRow(['', `${iClass}. ročník`, count]);
          countRow.getCell(2).font = { bold: true, size: 12 };
          countRow.getCell(3).font = { bold: true, size: 12 };
          countRow.getCell(2).fill = {
            fgColor: { argb: '82CA40' },
            pattern: 'solid',
            type: 'pattern',
          };
          countRow.getCell(3).fill = {
            fgColor: { argb: '82CA40' },
            pattern: 'solid',
            type: 'pattern',
          };
          iClass = row[2];
          count = 1;
          totalCount = totalCount + 1;
        } else {
          count = count + 1;
          totalCount = totalCount + 1;
        }
        const addedRow = worksheet.addRow(row);
        addedRow.getCell(1).font = { size: 12 };
        addedRow.getCell(2).font = { size: 12 };
        addedRow.getCell(3).font = { size: 12 };
      });

      if (totalCount > 0) {
        const lastCountRow = worksheet.addRow(['', `${iClass}. ročník`, count]);
        lastCountRow.getCell(2).font = { bold: true, size: 12 };
        lastCountRow.getCell(3).font = { bold: true, size: 12 };
        lastCountRow.getCell(2).fill = {
          fgColor: { argb: '82CA40' },
          pattern: 'solid',
          type: 'pattern',
        };
        lastCountRow.getCell(3).fill = {
          fgColor: { argb: '82CA40' },
          pattern: 'solid',
          type: 'pattern',
        };
      }
      const totalCountRow = worksheet.addRow(['', 'Celkový počet', totalCount]);
      totalCountRow.getCell(2).font = { bold: true, size: 12 };
      totalCountRow.getCell(3).font = { bold: true, size: 12 };
      totalCountRow.getCell(2).fill = {
        fgColor: { argb: '82CA40' },
        pattern: 'solid',
        type: 'pattern',
      };
      totalCountRow.getCell(3).fill = {
        fgColor: { argb: '82CA40' },
        pattern: 'solid',
        type: 'pattern',
      };

      worksheet.eachRow((row, rowNumber) => {
        row.getCell(1).border = {
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
          top: { style: 'thin' },
        };
        row.getCell(2).border = {
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
          top: { style: 'thin' },
        };
        row.getCell(3).border = {
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
          top: { style: 'thin' },
        };
      });

      // Generate Excel file buffer
      const buffer = await workbook.xlsx.writeBuffer();

      // Save the Excel file
      saveAs(new Blob([buffer]), 'ziaci.xlsx');
    } catch (error) {
      console.error('Error exporting data to Excel:', error);
    }
  }

  return (
    <IconButton
      aria-label='download excel'
      color='primary'
      component='span'
      onClick={handleExportToExcel}
    >
      <ReceiptIcon />
    </IconButton>
  );
};

export default Export;
